<template>
    <div class="b-print-view-header">
         <img src="@/assets/img/db-iso.svg" alt="DB" class="b-print-view-header__logo">

         <div class="b-print-view-header__main-info">
            <div class="b-print-view-header__trip-name"> 
               {{ tripName }} 
            </div>

            <div class="b-print-view-header__row-info">
               <span>
                  {{ $t('trip_code') }}: 
               </span>

               <span class="b-print-view-header__trip-code"> 
                  {{ tripCode }} 
               </span>
            </div>

            <div class="b-print-view-header__row-info">
               <span>
                  {{ $t('process') }}:
               </span>

               <span class="h-font-bold"> 
                  #{{ checkoutNumber }}
               </span>
            </div>

            <div class="b-print-view-header__row-info">
               <span>
                  {{ $t('account') }}: 
               </span>

               <span class="h-font-bold"> 
                  {{ customerAccountName }}  
               </span>
            </div>

            <div class="b-print-view-header__row-info">
               <span>
                  {{ $t('db_contact_name') }}: 
               </span>

               <span class="h-font-bold"> 
                  {{ responsibleName }} 
               </span>
            </div>
         </div>

         <!-- <div v-if="includeQR" class="b-print-view-header__qr-wrap">
            <div class="b-print-view-header__qr-title"> {{ $t("trip_detail_access") }} </div>

            <img
               alt="Trip detail access"
               class="b-print-view-header__qr-code"
               :src="qrCode"
            />
         </div> -->
      </div>
</template>

<script>
   import { mapState } from 'vuex';

   export default {
      props: {
         includeQR: {
            type: Boolean,
            default: false,
         }
      },
      data() {
         return {
         }
      },
      computed: {
         ...mapState({
            tripName: state => state.tripName,
            tripCode: state => state.tripCode,
            customerAccountName: state => state.customerAccount ? state.customerAccount.name : '',
            checkoutNumber: state => state.checkoutNumber,
            responsibleName: state => state.responsible ? state.responsible.name : '',
            printViewLink:state=> state.printViewLink
         }),
         qrCode() {
            return `https://chart.googleapis.com/chart?chs=150x150&cht=qr&chl=
               ${this.printViewLink}&choe=UTF-8&chld=L|1`;
         },
      },
    };
</script>

<style lang="less">
    .b-print-view-header {
       padding-bottom: 10pt;
       border-bottom: solid 2px var(--color-red);
       .h-flex-row-left-top;

       &__logo {
          width: 50pt;
          height: auto;
          margin-right: 15pt;
       }

       &__main-info {
          .flex-grow(1);
       }

       &__trip-name {
          font-size: 28pt;
          margin-bottom: 5pt;
          line-height: 1;
          text-transform: uppercase;
          .h-font-black;
       }

       &__row-info {
          .h-font-bold {
             margin-right: 10pt;
          }
       }

       &__trip-code {
          font-size: 20pt;
          text-transform: uppercase;
          .h-font-bold;
       }

       &__qr-wrap {
          .h-flex-col;
          .align-items(flex-end);
       }

       &__qr-title {
          color: var(--color-gray-2);
          margin-bottom: 3pt;
       }

       &__qr-code {
          width: 95pt;
          height: 95pt;
       }
    }
</style>